/* .testimonial-carousel .t-wrap {
  background-color: #fff;
  box-shadow: 0 1px 20px rgb(0 0 0 / 4%);
  margin: 10px;
  padding: 20px 35px 20px 25px;
  height: 256px;
} */

.testimonial-carousel .t-wrap {
  background-color: #fff;
  box-shadow: 0 1px 20px rgb(0 0 0 / 4%);
  margin: 6px;
  padding: 10px 26px 10px 28px;
  height: 297px;
}

.testimonial-carousel .t-wrap .icon {
  color: var(--cl-primary-color);
  font-size: 52px;
}

.testimonial-carousel .t-wrap .t-content {
  padding-left: 25px;
}

.testimonial-carousel .t-wrap .t-info {
  display: flex;
}

.testimonial-carousel .t-wrap .t-content p {
  margin-bottom: 0;
}

/* .testimonial-carousel .t-wrap .t-content span {
  margin-top: 50px;
} */
/* .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 60%;
} */

.testimonial-carousel .t-wrap .t-info .img-wrap {
  margin-right: 12px;
  width: 50px;
}

.testimonial-carousel .t-wrap .t-info .name span {
  display: block;
  font-size: 12px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-prev {
  left: -12px;
}

.slick-next {
  right: -12px;
}

.testimonial-carousel.owl-theme .owl-nav .owl-prev {
  left: -19px;
}

.testimonial-carousel.owl-theme .owl-nav .owl-next {
  right: -19px;
}

.loader-test {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-info {
  margin-top: 24px;
}

.popup-content {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 20px;
}

.popup-image img {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}

.popup-name p {
  margin-bottom: 0;
}

.icon-testimonial {
  color: var(--cl-primary-color);
  font-size: 52px;
}

.testimonial-popup .modal-header {
  border-bottom: none;
}

.testimonial-popup .modal-footer {
  border-top: none;
}

@media screen and (max-width: 1199px) {
  .text-limit {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .text-limit {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .test-test {
    margin-bottom: 96px;
  }
}
