.contact-section .bl {
  border-left: 1px solid #dedede;
  margin-left: 80px;
  padding-left: 65px;
}

.p-header .maintitle {
  margin-bottom: 16px;
}

.p-header .subtitle {
  margin-bottom: 4px;
}

.loader-contactData {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 70px;
}

.contact-section .contact-info li .phone {
  color: #606060;
}

.contact-form .cl-button:hover {
  color: var(--cl-primary-color);
}

.contact-section .contact-info p {
  margin-bottom: 2rem;
}

.contact-section {
  margin-bottom: 96px;
}

.subtitle-contact {
  color: var(--cl-primary-color);
}

.location-map {
  margin-bottom: 96px;
}

.contact-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 28px;
}

.contact-section .phone {
  font-size: 19px;
  font-weight: 700;
}
@media screen and (max-width: 1199px) {
  .contact-section .bl {
    margin-left: 0;
    padding-left: 35px;
    /* margin-top: 20px; */
  }
}

@media screen and (max-width: 768px) {
  .contact-section .bl {
    margin-top: 20px;
    border-left: 0;
  }

  .contact-section {
    margin-bottom: 65px;
  }

  .p-header .subtitle .contact {
    margin-bottom: -15px;
    margin-top: -15px;
  }

  .location-map {
    margin-bottom: 65px;
  }

  /* .contact-section .contact-form {
    /* margin-left: -27px; */
}

@media screen and (max-width: 567px) {
  .contact-info {
    margin-left: 28px;
  }

  .contact-form {
    margin-right: 2px;
    margin-left: -19px;
  }

  .contact-section .contact-list-info {
    margin-left: 2px;
  }

  .contact-section .contact-list {
    margin-left: 2px;
  }
  .contact-section .contact-info p {
    margin-left: 1px;
  }

  .p-header .subtitle {
    margin-left: -5px;
  }

  .p-header .maintitle {
    margin-left: -6px;
  }
  .location-map {
    margin-bottom: 48px;
  }

  .contact-section .bl {
    padding-left: 34px;
  }
}
